@import url('./styles/fonts.css');
body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  box-sizing: border-box;
  overflow: hidden;
}

html *,
body * {
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  html,
  body {
    overflow: visible;
  }
}
@media screen and (max-height: 820px) and (orientation: landscape) {
  html,
  body {
    overflow: visible;
  }
}
@media print {
  html,
  body {
    overflow: visible;
  }
}
