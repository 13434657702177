@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Book.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Gotham';
  src: url('../assets/fonts/Gotham-Black.woff') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Semibold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/ProximaNova-Extrabld.woff') format('woff');
  font-weight: 800;
}
